@import "../../assets/css/variables";

.controls {
	margin-bottom: 3px;
	background: $c-bg-editor-1;
    color: #bfc2c5;
	label {
		display: flex;
        align-items: center;
		margin: 0;
	}
    .MuiFormControlLabel-root {
        margin: 0 !important;
    }
	input[type="text"] {
		padding: 10px;
		font-size: 12px;
	}
    .MuiButtonBase-root {
		font-size: 12px !important;
		height: auto;
	}
	.MuiRadio-root {
		display: none;
		& + span {
			padding: 5px 5px;
			display: block;
			margin: 0 !important;
			border: none;
            border-radius: 3px;
		}
		&.Mui-checked + span {
			border-color: #3b82f6;
            color: #fff;
            background-color: $c-bg-editor-1;
		}
	}
	.radio-group {
		display: flex;
		align-items: center;
        border-radius: 8px;
        background-color: $c-bg-editor-2;
        border: 1px solid $c-border;
        position: relative;
		&>label {
			flex: 1;
			text-align: center;
            z-index: 1;
            padding: 5px;
            display: block;
		}
	}
	fieldset {
        color: #bfc2c5;
        border-color: #3a3d40;
		border-radius: 0 !important;
        border-radius: 8px !important;
	}
	.MuiInputBase-input {
        border-radius: 8px !important;
        background-color: $c-bg-editor-2;
		padding: 10px !important;
		font-size: 12px !important;
        &::placeholder {
            font-size: 12px !important;
        }
	}
    .MuiFormControl-root,
    .MuiFormGroup-root {
        margin-bottom: 0 !important;
    }
}

.controls__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    user-select: none;
}

.controls__header-label {
	font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .08em;
    text-transform: uppercase;
    color: #fff;
}

.controls__body {
    padding: 0 20px 20px;
    .controls--collapsed & {
        display: none;
    }
}

.controls__field-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
    & > * {
        width: 50% !important;
    }
    .MuiSwitch-root {
        width: 58px !important;
        margin-right: -10px;
    }
    .MuiSwitch-switchBase {
        top: 4px;
        background: none !important;
    }
    .MuiSelect-select {
        height: 1.4375em !important;
        display: flex;
        align-items: center;
    }
    &.controls__field-group--label-block > * {
        width: 100% !important;
        margin-bottom: 5px;
    }
    &.controls__field-group--switch {
        label {
            width: 70% !important;
        }
    }
    textarea {
        background-color: $c-bg-editor-2;
        width: 100%;
        border-radius: 8px;
        padding: 15px;
        border: 1px solid $c-border;
        color: #fff;
        outline: none;
        resize: none;
        font-size: 12px;
        font-family: inherit;
    }
}

.controls__field-group--selector .MuiFormControl-root {
    background-color: $c-bg-editor-2;
    border-radius: 8px;
}

label.label-flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.controls__button {
    padding: 10px;
    text-align: center;
    background: $c-bg-editor-2;
    border-radius: 8px;
    border: 1px solid $c-border;
    cursor: pointer;
}

.controls__header-icon svg {
    width: 100%;
    height: 100%;
}

.controls__header-icon {
    width: 16px;
    height: 16px;
    transition: all .3s ease;
    .controls--collapsed & {
        transform: rotate(-90deg);
    }
}

.control-select-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    color: $c-body;
    pointer-events: none;
    background-color: $c-bg-editor-2;
}

.control-description {
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
}

.selector-target {
    font-size: 24px;
    display: flex;
    color: #c5c5c5;
    cursor: pointer;
    &.active {
        color: $c-bl;
    }
}

.react-tagsinput {
    background-color: $c-bg-editor-2 !important;
    border: 1px solid $c-border !important;
    padding: 5px 0 0 5px !important;
    border-radius: 8px;
}

.react-tagsinput-input {
    width: 100px !important;
    margin-bottom: 5px !important;
    color: #fff !important;
    padding: 7px !important;
}

.react-tagsinput-tag {
    background-color: $c-bg-editor-1 !important;
    color: #fff !important;
    border-radius: 3px !important;
    padding: 6px !important;
    border: none !important;
    font-size: 12px !important;
    font-family: Inter !important;
}

.react-tagsinput-remove {
    padding: 5px;
}

.code-wrapper {
    height: 105px;
    overflow: auto;
    background-color: $c-bg-editor-2;
    width: 100%;
    border-radius: 8px;
    padding: 15px;
    border: 1px solid $c-border;
    .dialog__content & {
        background-color: $c-bg-editor-3;
    }
    /* width */
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: $c-bg-editor-2;
		border-radius: 0 8px 8px 0;
        .dialog__content & {
            background: $c-bg-editor-3;
        }
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #555;
		border-radius: 8px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.w-tc-editor {
    background: none !important;
    overflow: auto !important;
    textarea {
        padding: 0 !important;
        border-radius: 0 !important;
        min-height: 40px !important;
    }
}

.w-tc-editor-preview {
    padding: 0 !important;
    min-height: 70px !important;
    .punctuation {
        color: #bb78c1 !important;
    }
}

.invalid-message {
    font-size: 12px;
    margin-top: 5px;
    display: none;
    .error & {
        display: block;
    }
}