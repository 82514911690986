@import '../../assets/css/variables';

.timeline {
  background: $c-bg-editor-2;
  position: relative;
}

.timeline__bar {
  padding: 10px 10px 0;
  position: relative;
  display: flex;
  justify-content: center;
  & > div {
    width: calc(100% / 3);
    .editor--full & {
      width: auto;
    }
  }
  .editor--full & {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(33 36 38 / 66%);
    padding: 10px;
    border-radius: 5px;
  }
}

.timeline__bar-center {
  display: flex;
  justify-content: center;
  .editor--full & {
    margin-left: 10px;
  }
}

.timeline__bar-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  .timeline__bar-right & {
    justify-content: flex-end;
  }
}

.timeline__bar-button {
  .editor--full &.timeline__bar-button--add {
    display: none;
  }
}

.timeline__bar-button-inner {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: $c-bg-editor-1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: solid 1px transparent;
  &.active {
    border: 1px solid #7f7f7f;
  }
  .timeline__bar-button--text & {
    width: auto;
    font-size: 12px;
    padding: 5px 10px;
  }
}

.timeline__content {
  padding: 10px 5px;
  height: 200px;
  overflow-y: hidden;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $c-bg-editor-1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .editor--full & {
    height: 0;
    padding: 0;
  }
}

.timeline__content-inner {
  position: relative;
}

.timeline__bar-progress {
  position: relative;
}

.timeline__content-inner {
  width: 2510px;
}

.timeline__bar-progress-thumb-wrapper {
  z-index: 1;
  top: -40px !important;
  height: 100% !important;
}

.timeline__bar-progress-thumb {
  width: 0;
  height: calc(100% + 46px);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #2563eb;
  transform: translateX(-3px);
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: -0.5px;
    background-color: #2563eb;
  }
}

.timeline__bar-progress-time {
  display: flex;
  margin-bottom: 10px;
  padding: 0 5px;
}

.timeline__bar-progress-time-item {
  width: 250px;
  height: 15px;
  display: flex;
  position: relative;
  &:first-child {
    border-left: solid 1px #393939;
  }
}

.timeline__bar-progress-time-item-split {
  width: 25px;
  border-right: solid 1px #393939;
}

.timeline__bar-progress-time-item-text {
  position: absolute;
  top: -1px;
  left: calc(100% + 3px);
  font-size: 10px;
  &.timeline__bar-progress-time-item-text--zero {
    left: 3px;
  }
}

.timeline__animations {
  padding: 0px 5px;
}

.timeline__bar-right {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  .editor--full & {
    display: none;
  }
}