@import "../../assets/css/variables";

.panel {
    .MuiButtonBase-root {
        height: 30px;
        font-size: 12px !important;
    }
}

.panel__inner {
    padding-bottom: 50px;
}

.panel__footer {
    position: fixed;
    width: 320px;
    left: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px 20px;
    background: $c-bg-editor-1;
    border-top: 1px solid $c-border;
    display: flex;
    justify-content: space-between;
    transition: all 0.3s ease;
    .editor--full & {
		left: -320px;
	}
}

.panel__footer-right {
    display: flex;
    gap: 10px;
}

.panel__footer-left,
.panel__back {
    display: flex;
    align-items: center;
}

.panel__back {
    cursor: pointer;
}

.panel__button-export.MuiButton-contained:not(.Mui-disabled) {
    background: $c-bg-editor-2 !important;
    &:hover {
        background: #000 !important;
    }
}

.panel__logo {
    height: 15px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}