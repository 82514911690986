$mobile: 320px;
$mobile-ls: 480px;
$tablet: 768px;
$tablet-ls: 800px;
$laptop-bf: 920px;
$laptop: 992px;
$laptop-lg: 1200px;

$c-bg: #FBFBFD;
$c-bg-dark: #363740;
$c-bg-editor-1: #2b2f30;
$c-bg-editor-2: #212426;
$c-bg-editor-3: #151818;
$c-dark: #333;
$c-y: #724a2f;
$c-yl: #ffff00;
$c-yld: #F2D96E;
$c-pp: #540023;
$c-orange: #ff4200;
$c-gray: #A4A6B3;
$c-gray-light: #fbfbfb;
$c-gray-border: #ececec;
$c-gray-shadow: #c5c5c5;  
$c-w: #ffffff;
$c-black: #000;
$c-overlay: rgba(0,0,0,0.9);
$c-bl: #3364E3;
$c-bl-dark: #264fba;
$c-bl-light: #DDE2FF;
$c-pink: #e2449b;
$c-red: #ed1f24;
$c-red-dark: #cb1a21;
$c-red-light: #F05E6E;
$c-border: #3a3d40;
$c-border-light: #CDD6FC;
$c-body: #bfc2c5;

.c-red {
	color: $c-red !important;
}

.c-blue {
	color: $c-bl !important;
}

.cursor {
	cursor: pointer;
}